
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'one-third column alpha thumbnail even' }, _createElement('div', {}, _createElement('div', { 'className': 'cm_search-item thumbnail' }, _createElement('a', { 'href': this.url }, _createElement('div', { 'className': 'relative product_image' }, _createElement('div', { 'className': 'product_container swap-false' }, _createElement('div', { 'className': 'image__container' }, _createElement('img', {
                    'alt': this.removeHTML(this.title),
                    'className': 'lazyloaded',
                    'src': this.imageOrDefault(this.image)
                })))), _createElement('div', { 'className': 'info' }, _createElement('span', mergeProps({ 'className': 'cm_search-item_title title' }, { dangerouslySetInnerHTML: { __html: this.title } })), !this.out_of_stock ? _createElement('span', {
                    'className': 'price' + (this.on_sale ? ' sale' : ''),
                    'key': '549'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)), this.on_sale ? _createElement('span', {
                    'className': 'was_price',
                    'key': '720'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))) : null) : null, this.out_of_stock ? _createElement('span', {
                    'className': 'sold_out',
                    'key': '881'
                }, 'Sold Out') : null, this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '952'
                }) : null), this.on_sale ? _createElement('div', {
                    'className': 'sale_banner',
                    'key': '1098'
                }, 'Sale') : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement.apply(this, [
        'div',
        { 'className': 'cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []