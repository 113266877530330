//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-228:_2072,_5276,_6160,_3888,_7920,_9204,_1120,_6684;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-228')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-228', "_2072,_5276,_6160,_3888,_7920,_9204,_1120,_6684");
        }
      }catch (ex) {
        console.error(ex);
      }